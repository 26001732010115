@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./fonts/Montserrat-VariableFont_wght.ttf) format("truetype");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #e5b904;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: "Montserrat";
  color: white;
}

.App-header p {
  color: black;
  font-weight: 600;
  font-size: calc(6px + 5vmin);
  margin-top: 10px;
}

.App-header h1 {
  color: black;
  font-weight: 500;
  font-size: calc(20px + 2vmin);
}

.App-header h2 {
  color: black;
  font-weight: 300;
}

.App-link {
  color: #61dafb;
}
